import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import { red } from '@material-ui/core/colors';
// tslint:disable-next-line: no-submodule-imports
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IAssignmentsDto } from '../../types/dto.type';
import { get, put } from '../api/axios';
import { apiUrls, organizationUrl, projectUrl } from '../api/urls';
import { getProjectStreamsRoute } from '../routes/Routes';

const skeletonHeight = 30;
const skeletonStyle = 'wave';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    cardRoot: {
      height: '100%'
    },
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    },
    avatar: {
      backgroundColor: red[500]
    },
    disabledCard: {
      backgroundColor: red[100]
    },
    enabledCard: {}
  })
);

export default function ProjectAssignmentCard(
  props: IAssignmentsDto
): JSX.Element {
  const classes = useStyles();
  const [organizationName, setOrganizationName] = useState('');
  const [streams, setStreams] = useState<number[]>(props.streams);
  const [streamsLoaded, setStreamsLoaded] = useState<boolean>(false);
  const [projectEnabled, setProjectEnabled] = useState<boolean>(true);

  useEffect(
    () => {
      const fetchProject = async () => {
        const url = projectUrl(
          props.projectAssigned.organizationId,
          props.projectId
        );
        const data = await get(url);
        setProjectEnabled(data.enabled);
      };
      fetchProject();
    },
    [props.projectAssigned.organizationId, props.projectId]
  );

  useEffect(
    () => {
      const fetchOrganization = async () => {
        const url = organizationUrl(props.projectAssigned.organizationId);
        const data = await get(url);
        setOrganizationName(data.name);
      };
      fetchOrganization();
    },
    [props.projectAssigned.organizationId]
  );

  useEffect(
    () => {
      const fetchStreamIds = async () => {
        const url = apiUrls.projectStreams();
        const response = await put(url, {
          organizationId: props.projectAssigned.organizationId,
          projectName: props.projectId
        });
        if (response && response.data) {
          setStreams(response.data);
          setStreamsLoaded(true);
        }
      };
      fetchStreamIds();
    },
    [props.projectAssigned.organizationId, props.projectId]
  );

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-AU');
  };

  return (
    <Card
      className={clsx(classes.cardRoot, {
        [classes.enabledCard]: projectEnabled,
        [classes.disabledCard]: !projectEnabled
      })}
    >
      <CardActionArea
        className={classes.cardRoot}
        component={RouterLink}
        disabled={streams.length === 0}
        to={{
          pathname: getProjectStreamsRoute(props.projectId),
          state: {
            streams,
            projectId: props.projectId,
            projectName: props.projectAssigned.project
          }
        }}
      >
        <CardHeader
          avatar={
            !organizationName ? (
              <Skeleton
                animation='wave'
                variant='circle'
                width={40}
                height={40}
              />
            ) : (
              <Avatar aria-label='organization' className={classes.avatar}>
                {organizationName[0]}
              </Avatar>
            )
          }
          title={
            !organizationName ? (
              <Skeleton animation={skeletonStyle} height={skeletonHeight} />
            ) : (
              organizationName
            )
          }
          subheader={!projectEnabled ? 'This project has been disabled' : ''}
          titleTypographyProps={{ variant: 'h6' }}
        />
        <CardContent>
          <Typography variant='h5' component='h2' className={classes.pos}>
            {!organizationName ? (
              <Skeleton animation={skeletonStyle} height={skeletonHeight} />
            ) : (
              props.projectAssigned.project
            )}
          </Typography>
          {streamsLoaded ? (
            <Typography className={classes.pos} color='primary'>
              Streams assigned:{' '}
              {streams.length === 0 ? 'none' : streams.join(', ')}
            </Typography>
          ) : (
            <Skeleton animation={skeletonStyle} height={skeletonHeight} />
          )}
          {!organizationName ? (
            <div>
              <Skeleton animation={skeletonStyle} height={skeletonHeight} />
            </div>
          ) : (
            <div>
              <Typography variant='body2' component='p' color='textSecondary'>
                Start date: {formatDate(props.projectAssigned.startDate)}
              </Typography>
              <Typography variant='body2' component='p' color='textSecondary'>
                Planned end date:{' '}
                {formatDate(props.projectAssigned.plannedEndDate)}
              </Typography>
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
