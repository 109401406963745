import {
  createStyles,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography} from '@material-ui/core';
import {Label as LabelIcon} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import HelpNativePlaybackControlImage from '../images/help-playback-control.png';
import HelpVideoBarImage from '../images/help-video-bar-control.png';
import { AUTH, AUTHZ } from '../services';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center'
    },
    helpNumbering: {
      color: 'white',
      borderRadius: '50%',
      width: '2em',
      height: '2em',
      lineHeight: '2em',
      textAlign: 'center',
      background: '#6788B1',
      fontWeight: 'bold'
    }
  })
);

export default function HelpInfo() {
  const classes = useStyles();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (AUTH.isAuthenticated()) {
        setIsAdmin(await AUTHZ.permitAppRole('admin'));
      }
    };

    fetch();
  }, []);

  return (
  <>
    <DialogContent dividers={true}>
      <Typography variant='h6' gutterBottom={true}>
        Stream Player Controls
      </Typography>
      <List className={classes.root}>
        <ListItem alignItems='flex-start'>
          <ListItemText
            primary='Show native video controls'
            secondary={
              <>
                Right click on the video to bring up a menu. Click <i>Show controls</i>.
                <div>The native video player controls as shown below, will now appear when your mouse hovers over the video.</div>
              </>
            }
          />
        </ListItem>

        <div>
          <img src={HelpNativePlaybackControlImage}  alt='Help Native Video Playback Control' />
        </div>

        <ListItem alignItems='flex-start'>
          <List style={{maxWidth: '60em'}}>
            <ListItem alignItems='flex-start'>
                <ListItemAvatar>
                  <div className={classes.helpNumbering}>1</div>
                </ListItemAvatar>
                <ListItemText
              primary='Play & pause'
              secondary={
                <>
                  Play and pause the video playback.
                </>
              }
            />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>2</div>
              </ListItemAvatar>
              <ListItemText
                primary='Current playback position'
                secondary={
                  <>
                    The current time of playback relative to the total video duration.
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>3</div>
              </ListItemAvatar>
              <ListItemText
                primary='Total video duration'
                secondary={
                  <>
                    The total duration of the video stream. Effectively the duration we can go back in time and play back.
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>4</div>
              </ListItemAvatar>
              <ListItemText
                primary='Current playback position'
                secondary={
                  <>
                    This point is the current playback position of the entire available video stream.
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>5</div>
              </ListItemAvatar>
              <ListItemText
                primary='Buffered video'
                secondary={
                  <>
                    The light grey portion of video navigation bar shows buffered video ahead of playback.
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>6</div>
              </ListItemAvatar>
              <ListItemText
                primary='Volume and Mute'
                secondary={
                  <>
                    Control volume of video stream if supported by Sense Live server config.
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>7</div>
              </ListItemAvatar>
              <ListItemText
                primary='Full screen'
                secondary={
                  <>
                    Play the video steam in full screen mode. Press (Esc) key to exit.
                  </>
                }
              />
            </ListItem>
          </List>
        </ListItem>

        <Divider variant='inset' component='li' />

        <ListItem alignItems='flex-start'>
          <ListItemText
            primary='Video Bar'
            secondary={
              <>
                The <i>Video Bar</i> is displayed at the bottom of each video stream box by default as shown below:
              </>
            }
          />
        </ListItem>

        <div>
          <img src={HelpVideoBarImage}  alt='Help Video Bar' />
        </div>

        <ListItem>
          <List  style={{maxWidth: '60em'}}>
            <ListItem alignItems='flex-start'>
                <ListItemAvatar>
                  <div className={classes.helpNumbering}>1</div>
                </ListItemAvatar>
                <ListItemText
              primary='Channel number'
              secondary={
                <>
                  The channel number assigned to the video stream. This is a server-side construct.
                </>
              }
            />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>2</div>
              </ListItemAvatar>
              <ListItemText
                primary='Video stream title'
                secondary={
                  <>
                    The title of the video stream.
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>3</div>
              </ListItemAvatar>
              <ListItemText
                primary='Edit video stream title'
                secondary={
                  <>
                    Edit the video stream title as shown in item (2).
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>4</div>
              </ListItemAvatar>
              <ListItemText
                primary='HD view'
                secondary={
                  <>
                    Launches the HD view of the video stream. An increase in video quality is entirely dependant
                    on the video source and/or cameras used. Video resolution up to 1080p is supported.
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <div className={classes.helpNumbering}>5</div>
              </ListItemAvatar>
              <ListItemText
                primary='Seek to latest'
                secondary={
                  <>
                    This will bring the video back to live playback. That is the latest available video.
                  </>
                }
              />
            </ListItem>
          </List>
        </ListItem>
      </List>

      {isAdmin && (
        <>
          <Typography variant='h6' gutterBottom={true}>
            User Management
          </Typography>

          <List className={classes.root}  style={{maxWidth: '60em'}}>
            <ListItem alignItems='flex-start'>
              <ListItemText
                  primary='Deleting Users'
                  secondary={
                    <>
                      <div>There is an important distinction to highlight between the different ways of
                        deleting users.</div>
                    </>
                  }
                />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <LabelIcon/>
              </ListItemAvatar>
              <ListItemText
                primary='Delete user via global user page (left side menu)'
                secondary={
                  <div>
                    The global user page lists all users of Sense Live. Deleting a user from this
                    page <b>completely</b> removes the user from Sense Live including from all the projects they
                    are assigned to. External users (i.e. non-Fugro users) will lose login access to the Sense
                    Live app. If the same user is added to Sense Live in the future, they will need to be
                    onboarded again (following email validation and set password process).
                  </div>
                }
              />
            </ListItem>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <LabelIcon/>
              </ListItemAvatar>
              <ListItemText
                primary='Deleting user from specific project'
                secondary={
                  <>
                    <div>
                      An admin can view users assigned to a specific project by starting from the Organisations
                      page (left side menu) and selecting the desired Organisation and contained Project.
                    </div>
                    <div>
                      Deleting a user here will remove the user (revoke user access) from this project only.
                      The user will remain a user of Sense Live, whether they are assigned to any other projects
                      or not. If they are assigned to other projects they will continue to see them listed on
                      the Sense Live dashboard, otherwise a <i>no projects assigned</i> message will be displayed.
                    </div>
                  </>
                }
              />
            </ListItem>
          </List>
        </>
      )}
    </DialogContent>
  </>
  );
}
