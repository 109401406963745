import { AuthZExtendClaimsReq } from '../common/authZ.type';
import { AuthZClaim } from '../types/authZClaim';
import { put } from './axios';
import { apiUrls } from './urls';

export async function fetchExtendClaims(
  authZReq: AuthZExtendClaimsReq
): Promise<AuthZClaim> {
  const url = apiUrls.authZExtendClaims();
  const response = await put(url, authZReq);

  if (!response) {
    throw new Error('Failed to get data from API');
  }

  return AuthZClaim.deserialize(response.data, authZReq.claims.email);
}
