import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CONNECTION } from '../api/auth0.api';
import { changePasswordUrlV2 } from '../common/auth0Urls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    container: {
      marginTop: '8em',
      textAlign: 'center'
    },
    input: {
      margin: theme.spacing(1),
      width: '280px'
    },
    button: {
      margin: theme.spacing(1)
    }
  })
);

export default function Verification(props: RouteComponentProps): JSX.Element {
  const [message, setMessage] = useState<string>('');

  const parsed = queryString.parse(props.location.search);
  const email = parsed.email as string;

  useEffect(() => {
    const resetPassword = async () => {
      const url = changePasswordUrlV2();
      const postBody = {
        email,
        connection: CONNECTION
      };
      const response = await axios.post(url, postBody);
      if (response.status === 200) {
        setMessage(
          `The email ${email} has been verified, another email has been sent to reset the password.`
        );
      } else {
        setMessage(
          `The email ${email} has been verified, but there is a problem with the process please contact your Sense Live administrator.`
        );
      }
    };
    resetPassword();
  }, [email]);

  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant='h6' gutterBottom={true}>
        {message}
      </Typography>
    </Container>
  );
}
