// tslint:disable-next-line: no-submodule-imports
import { useWindowSize } from '@react-hook/window-size/throttled';
import { useCallback, useEffect, useState } from 'react';

export default function useCalculatedWidth(
  widthAdj: number,
  heightAdj: number,
  videoRatio: number
): string {
  const [windowWidth, windowHeight] = useWindowSize();

  const flexContainerWidth = useCallback(
    () => {
      const layoutWidth = () => {
        const calc = (windowHeight - heightAdj) * videoRatio;
        return `${calc}px`;
      };
      if ((windowWidth - widthAdj) / (windowHeight - heightAdj) > 1.7) {
        return layoutWidth();
      } else {
        return 'calc(100%)';
      }
    },
    [widthAdj, heightAdj, windowWidth, windowHeight, videoRatio]
  );

  const [size, setSize] = useState(flexContainerWidth());

  useEffect(
    () => {
      setSize(flexContainerWidth());
    },
    [flexContainerWidth]
  );

  return size;
}
