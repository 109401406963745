import {
  Avatar,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Tooltip
} from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { IIdTokenDto } from '../../types/dto.type';
import { put } from '../api/axios';
import { auth0UserUrl } from '../api/urls';
import { AUTH } from '../services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4)
    }
  })
);

export default function Logout(): JSX.Element {
  function handleLogout(): void {
    AUTH.logout();
  }

  const [profile, setProfile] = useState<any>();

  useEffect(() => {
    const handleLogin = async () => {
      const decoded = jwt_decode(AUTH.getIdToken()) as IIdTokenDto;
      const userProfile = await put(auth0UserUrl(), {
        userId: decoded.sub
      });
      setProfile(userProfile?.data);
    };
    handleLogin();
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Tooltip title={profile ? profile.email : ''}>
        <Avatar
          className={classes.small}
          alt={profile ? profile.name : ''}
          src={profile ? profile.picture : ''}
        />
      </Tooltip>
      <Button color='inherit' onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );
}
