import {
  ApiConfig,
  Auth0ManagementApiConfig,
  AuthConfig,
  AuthZApiConfig,
  AwsConfig,
  IConfig,
  StreamingConfig
} from '../common/config';

export class Config implements IConfig {
  public readonly api: ApiConfig;
  public readonly auth: AuthConfig;
  public readonly auth0ManagementApi: Auth0ManagementApiConfig;
  public readonly authZApi: AuthZApiConfig;
  public readonly aws: AwsConfig;
  public readonly streaming: StreamingConfig;
  public readonly name: string;
  public readonly cloudfrontStaticContentUrl: string;
  public readonly remoteOperationsSupervisorEmails: string;

  constructor() {
    let config: IConfig;
    if (process.env.REACT_APP_ENVIRONMENT === undefined) {
      console.error(
        'Environment variable REACT_APP_ENVIRONMENT is undefined, using \'dev\' environment'
      );
      config = this.loadConfig('dev');
    } else {
      const env = process.env.REACT_APP_ENVIRONMENT.toLowerCase();
      config = this.loadConfig(env);
    }
    this.api = config.api;
    this.auth = config.auth;
    this.auth0ManagementApi = config.auth0ManagementApi;
    this.authZApi = config.authZApi;
    this.aws = new AwsConfig();
    this.streaming = config.streaming;
    this.name = config.name;
    this.cloudfrontStaticContentUrl = config.cloudfrontStaticContentUrl;
    this.remoteOperationsSupervisorEmails =
      config.remoteOperationsSupervisorEmails;
  }

  private loadConfig(env: string) {
    let config: IConfig;
    config = require(`../common/environments/${env}.json`);
    console.log(`Client loaded ${env}.json`);
    return config;
  }
}
