import { createMuiTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { App } from './app';
import history from './History';
import * as serviceWorker from './serviceWorker';
import './stickyFooter.css';
import { GlobalStateProvider } from './store/globalState';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#011e41'
    },
    secondary: {
      main: '#6788b1'
    },
    text: {
      primary: '#011e41'
    }
  }
});

ReactDOM.render(
  <GlobalStateProvider>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <Router history={history}>
          <App />
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  </GlobalStateProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
