import {
  AppBar,
  Dialog,
  DialogTitle,
  IconButton,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Close as CloseIcon, Help as HelpIcon, Menu } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import FugroLogo from '../../images/fugro-logo-wh.png';
import { AUTH } from '../../services';
import { useGlobalState } from '../../store/globalState';
import HelpInfo from '../HelpInfo';
import Login from '../Login';
import Logout from '../Logout';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    hide: {
      display: 'none'
    },
    menuButton: {
      marginLeft: -20,
      marginRight: theme.spacing(2)
    },
    appBarLogo: {
      width: '8em'
    },
    title: {
      flexGrow: 1
    },
    helpIcon: {
      color: 'white',
      marginBottom: '4px',
      fontSize: '2.2875rem'
    },
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })
);

export function RenderAppBar(): JSX.Element {
  const [openDrawer, setOpenDrawer] = useGlobalState('globalOpenDrawer');
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  function handleDrawerOpen() {
    setOpenDrawer(true);
  }

  const handleOpenHelp = () => {
    setOpenHelpDialog(true);
  };

  const handleCloseHelp = () => {
    setOpenHelpDialog(false);
  };

  const classes = useStyles();

  return (
    <AppBar
      position='fixed'
      className={clsx(classes.appBar, openDrawer && classes.appBarShift)}
    >
      <Toolbar>
        {AUTH.isAuthenticated() && (
          <IconButton
            color='inherit'
            aria-label='Open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, openDrawer && classes.hide)}
          >
            <Menu />
          </IconButton>
        )}
        <img src={FugroLogo} className={classes.appBarLogo} alt='FugroLogo' />
        <Typography variant='h6' className={classes.title} />
        {AUTH.isAuthenticated() && (
          <IconButton
            color='inherit'
            aria-label='help'
            onClick={handleOpenHelp}
          >
            <HelpIcon className={classes.helpIcon} fontSize='large' />
          </IconButton>
        )}

        {!AUTH.isAuthenticated() && <Login />}
        {AUTH.isAuthenticated() && <Logout />}
      </Toolbar>
      <Dialog
        onClose={handleCloseHelp}
        aria-labelledby='simple-dialog-title'
        open={openHelpDialog}
        fullWidth={false}
        maxWidth={false}
      >
        <DialogTitle disableTypography={true} className={classes.root}>
          <Typography variant='h6'>Help and user tips</Typography>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleCloseHelp}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <HelpInfo />
      </Dialog>
    </AppBar>
  );
}
