import {
  Divider,
  Link,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { get } from '../../api/axios';
import { gitDescribeUrl } from '../../api/urls';
import Logo from '../../images/logo-wordmark_rgb_en_qb.png';
import { AUTH } from '../../services';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  footerLogo: {
    height: '2em'
  }
}));

export default function Footer(): JSX.Element {
  const [version, setVersion] = useState<string>('');

  useEffect(() => {
    const getVersion = async () => {
      if (AUTH.isAuthenticated()) {
        const response = await get(gitDescribeUrl());
        setVersion(response);
      }
    };
    getVersion();
  }, []);

  const classes = useStyles();

  return (
    <div className='footer'>
      <Divider />
      <footer className={classes.footer}>
        <Typography
          align='center'
          color='textPrimary'
          variant='subtitle1'
          gutterBottom={true}
        >
          {'\u00A9 '}
          <Link color='textPrimary' href='https://www.fugro.com/'>
            Fugro {new Date().getFullYear()}
          </Link>
          {', powered by '}
        </Typography>
        <img src={Logo} className={classes.footerLogo} alt='Fugo' />
        <Typography color='textSecondary'>{version}</Typography>
      </footer>
    </div>
  );
}
