import { CONFIG } from '../services';

export const apiUrls = {
  authZExtendClaims,
  authZAdminClaim,
  authZAssignChannels,
  projectStreams,
  projectStreamsV2
};

export function authZExtendClaims(): string {
  return `${CONFIG.api.baseUrl}/api/authz/extend-claims`;
}

export function authorizedStreams(): string {
  return `${CONFIG.api.baseUrl}/api/streams`;
}

function authZAdminClaim(): string {
  return `${CONFIG.api.baseUrl}/api/authz/admin/claim`;
}

function authZAssignChannels(): string {
  return `${CONFIG.api.baseUrl}/api/authz/assign-channels`;
}

function projectStreams(): string {
  return `${CONFIG.api.baseUrl}/api/project-streams`;
}

function projectStreamsV2(id: string): string {
  return `${CONFIG.api.baseUrl}/api/project-streams/${id}`;
}

export function organizationUrl(id: string): string {
  return `${CONFIG.api.baseUrl}/api/organizations/${id}`;
}

export function projectUrl(organizationId: string, projectId: string): string {
  return `${CONFIG.api.baseUrl}/api/organizations/${organizationId}/projects/${projectId}`;
}

export function streamNameUrl(id: string): string {
  return `${CONFIG.api.baseUrl}/api/stream-names/${id}`;
}

export function streamNamesUrl(): string {
  return `${CONFIG.api.baseUrl}/api/stream-names`;
}

export function gitDescribeUrl(): string {
  return `${CONFIG.api.baseUrl}/api/version`;
}

export function auth0UserUrl(): string {
  return `${CONFIG.api.baseUrl}/api/auth0/user`;
}
