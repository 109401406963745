import { Breadcrumbs, Link, Typography } from '@material-ui/core';
// tslint:disable-next-line: no-submodule-imports
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, match, useRouteMatch } from 'react-router-dom';
import {
  IOrganizationDto,
  IProjectDto,
  IProjectStreamsDto
} from '../../../types/dto.type';
import { get } from '../../api/axios';
import {
  apiUrls,
  organizationUrl,
  projectUrl,
  streamNameUrl
} from '../../api/urls';
import {
  getProjectStreamsRoute,
  getStreamName,
  UiRoutes
} from '../../routes/Routes';
import {
  HdStreamParams,
  OrganizationParams,
  OrganizationProjectParams,
  ProjectIdParams
} from '../../types/props.type';

export default function AppBreadcrumbs() {
  const homeMatches = useRouteMatch({
    path: [UiRoutes.root, UiRoutes.home, UiRoutes.projectAssignments],
    exact: true
  });

  const projectStreamsMatches = useRouteMatch<ProjectIdParams>({
    path: [UiRoutes.projectStreams],
    exact: true
  });

  const hdStreamMatches = useRouteMatch<HdStreamParams>({
    path: [UiRoutes.projectHdStreams],
    exact: true
  });

  const organizationsMatches = useRouteMatch({
    path: [UiRoutes.organizations],
    exact: true
  });

  const usersMatches = useRouteMatch({
    path: [UiRoutes.users],
    exact: true
  });

  const channelsMatches = useRouteMatch({
    path: [UiRoutes.channels],
    exact: true
  });

  const projectsMatches = useRouteMatch<OrganizationParams>({
    path: [UiRoutes.projects],
    exact: true
  });

  const projectUsersMatches = useRouteMatch<OrganizationProjectParams>({
    path: [UiRoutes.projectUsers],
    exact: true
  });

  const [busy, setBusy] = useState<boolean>(false);
  const [breadcrumbs, setBreadscrumbs] = useState<JSX.Element[]>([]);

  const getProjectStreamsProps = async (
    matches: match<ProjectIdParams> | match<HdStreamParams>
  ): Promise<IProjectStreamsDto> => {
    const projectId = matches.params.projectId;
    const url = apiUrls.projectStreamsV2(projectId);
    const props = (await get(url)) as IProjectStreamsDto;
    return props;
  };

  const getHdStreamName = async (
    matches: match<HdStreamParams>
  ): Promise<string> => {
    const streamId = matches.params.id;
    const response = await get(streamNameUrl(streamId));
    let streamName = getStreamName(parseInt(streamId, 10));
    if (response) {
      streamName = response.name;
    }
    return streamName;
  };

  const getOrganizationName = async (
    matches: match<OrganizationParams> | match<OrganizationProjectParams>
  ): Promise<string> => {
    const organizationId = matches.params.organizationId;
    const url = organizationUrl(organizationId);
    const organizationDto = (await get(url)) as IOrganizationDto;
    return organizationDto.name;
  };

  const getProjectName = async (
    matches: match<OrganizationProjectParams>
  ): Promise<string> => {
    const organizationId = matches.params.organizationId;
    const projectId = matches.params.projectId;
    const url = projectUrl(organizationId, projectId);
    const projectDto = (await get(url)) as IProjectDto;
    return projectDto.name;
  };

  useEffect(() => {
    const buffer: JSX.Element[] = [];
    if (homeMatches) {
      buffer.push(
        <Typography color='textPrimary' key={UiRoutes.home}>
          Home
        </Typography>
      );
      setBreadscrumbs(buffer);
    }
    // eslint-disable-next-line
  }, [homeMatches?.url]);

  useEffect(() => {
    const buffer: JSX.Element[] = [];
    const buildBreadscrumbs = async () => {
      if (projectStreamsMatches) {
        setBusy(true);
        buffer.push(
          <Link
            component={RouterLink}
            color='inherit'
            to={UiRoutes.home}
            key={UiRoutes.home}
          >
            Home
          </Link>
        );
        const projectName = (
          await getProjectStreamsProps(projectStreamsMatches)
        ).projectName;
        if (projectName) {
          buffer.push(
            <Typography color='textPrimary' key={projectStreamsMatches.path}>
              {projectName} streams
            </Typography>
          );
        }
        setBreadscrumbs(buffer);
        setBusy(false);
      }
    };
    buildBreadscrumbs();
    // eslint-disable-next-line
  }, [projectStreamsMatches?.url]);

  useEffect(
    () => {
      const buffer: JSX.Element[] = [];
      const buildBreadscrumbs = async () => {
        if (hdStreamMatches) {
          setBusy(true);
          buffer.push(
            <Link
              component={RouterLink}
              color='inherit'
              to={UiRoutes.home}
              key={UiRoutes.home}
            >
              Home
            </Link>
          );
          const projectName = (await getProjectStreamsProps(hdStreamMatches))
            .projectName;
          if (projectName) {
            buffer.push(
              <Link
                component={RouterLink}
                color='inherit'
                to={{
                  pathname: getProjectStreamsRoute(
                    hdStreamMatches.params.projectId
                  ),
                  state: await getProjectStreamsProps(hdStreamMatches)
                }}
                key={UiRoutes.projectAssignments}
              >
                {projectName} streams
              </Link>
            );
          }
          const streamName = await getHdStreamName(hdStreamMatches);
          buffer.push(
            <Typography color='textPrimary' key={hdStreamMatches.path}>
              {streamName}
            </Typography>
          );
          setBreadscrumbs(buffer);
          setBusy(false);
        }
      };
      buildBreadscrumbs();
    },
    // eslint-disable-next-line
    [hdStreamMatches?.url]
  );

  useEffect(() => {
    const buffer: JSX.Element[] = [];
    if (organizationsMatches) {
      buffer.push(
        <Typography color='textPrimary' key={UiRoutes.organizations}>
          Organizations
        </Typography>
      );
      setBreadscrumbs(buffer);
    }
    // eslint-disable-next-line
  }, [organizationsMatches?.url]);

  useEffect(() => {
    const buffer: JSX.Element[] = [];
    if (usersMatches) {
      buffer.push(
        <Typography color='textPrimary' key={UiRoutes.users}>
          Users
        </Typography>
      );
      setBreadscrumbs(buffer);
    }
    // eslint-disable-next-line
  }, [usersMatches?.url]);

  useEffect(() => {
    const buffer: JSX.Element[] = [];
    if (channelsMatches) {
      buffer.push(
        <Typography color='textPrimary' key={UiRoutes.channels}>
          Channels
        </Typography>
      );
      setBreadscrumbs(buffer);
    }
    // eslint-disable-next-line
  }, [channelsMatches?.url]);

  useEffect(() => {
    const buffer: JSX.Element[] = [];
    const buildBreadscrumbs = async () => {
      if (projectsMatches) {
        setBusy(true);
        buffer.push(
          <Link
            component={RouterLink}
            color='inherit'
            to={UiRoutes.organizations}
            key={UiRoutes.organizations}
          >
            Organizations
          </Link>
        );
        const organizationName = await getOrganizationName(projectsMatches);
        if (organizationName) {
          buffer.push(
            <Typography color='textPrimary' key={projectsMatches.path}>
              {organizationName} projects
            </Typography>
          );
        }
        setBreadscrumbs(buffer);
        setBusy(false);
      }
    };
    buildBreadscrumbs();
    // eslint-disable-next-line
  }, [projectsMatches?.url]);

  useEffect(
    () => {
      const buffer: JSX.Element[] = [];
      const buildBreadscrumbs = async () => {
        if (projectUsersMatches) {
          setBusy(true);
          buffer.push(
            <Link
              component={RouterLink}
              color='inherit'
              to={UiRoutes.organizations}
              key={UiRoutes.organizations}
            >
              Organizations
            </Link>
          );
          const organizationName = await getOrganizationName(
            projectUsersMatches
          );
          if (organizationName) {
            buffer.push(
              <Link
                component={RouterLink}
                color='inherit'
                to={UiRoutes.projects.replace(
                  ':organizationId',
                  projectUsersMatches.params.organizationId
                )}
                key={UiRoutes.projects}
              >
                {organizationName} projects
              </Link>
            );
          }
          const projectName = await getProjectName(projectUsersMatches);
          buffer.push(
            <Typography color='textPrimary' key={projectUsersMatches.path}>
              Users assigned to {projectName}
            </Typography>
          );
          setBreadscrumbs(buffer);
          setBusy(false);
        }
      };
      buildBreadscrumbs();
    },
    // eslint-disable-next-line
    [projectUsersMatches?.url]
  );

  return busy ? (
    <Skeleton variant='text' width={320} height={24} />
  ) : (
    <Breadcrumbs arial-label='Breadcrumb'>{breadcrumbs}</Breadcrumbs>
  );
}
