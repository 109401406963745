import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core';
// tslint:disable-next-line: no-submodule-imports
import { DialogProps } from '@material-ui/core/Dialog';
import React, { MouseEventHandler } from 'react';

export interface IConfirmationDialogProps extends DialogProps {
  open: boolean;
  title: string;
  message: string;
  yesHandler: MouseEventHandler;
  noHandler: MouseEventHandler;
  yesButtonDisabled: boolean;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  })
);

export default function ConfirmationDialog(
  props: IConfirmationDialogProps
): JSX.Element {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose({}, 'escapeKeyDown');
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.noHandler}
            color='primary'
            disabled={props.loading}
          >
            No
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={props.yesHandler}
              color='primary'
              autoFocus={true}
              disabled={props.yesButtonDisabled || props.loading}
            >
              Yes
            </Button>
            {props.loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
