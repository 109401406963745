import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from 'react-router-dom';
import { AUTH } from '../services';

interface PrivateRouteProps extends RouteProps {
  layout: React.ComponentType<RouteComponentProps<any>>;
}

export const PrivateRoute = ({
  component,
  layout,
  ...rest
}: PrivateRouteProps) => {
  if (!component) {
    throw Error('component is undefined');
  }

  const Component = component; // JSX Elements have to be uppercase.
  const Layout = layout;

  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (AUTH.isAuthenticated()) {
      return (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      );
    }
    return <Redirect to={{ pathname: '/login' }} />;
  };

  return <Route {...rest} render={render} />;
};
