import {
  Auth0DecodedHash,
  Auth0Error,
  Auth0UserProfile,
  AuthOptions,
  WebAuth
} from 'auth0-js';
import { RouteComponentProps } from 'react-router-dom';
import { AuthConfig } from '../common/config';
import { AUTHZ } from '../services';

const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_HOUR = 3600;
const MAX_HOURS = 4 * SECONDS_PER_HOUR;
const POLL_FREQUENCY = 5000;
const RENEWAL_BUFFER = 10000;

const ORIGIN = window.location.origin;
const ACCESS_TOKEN = `${ORIGIN}/accessToken`;
const ID_TOKEN = `${ORIGIN}/idToken`;
const EXPIRES_AT = `${ORIGIN}/expiresAt`;
const MANAGEMENT_API_TOKEN = `${ORIGIN}/managementApiToken`;
const AUTHZ_TOKEN = `${ORIGIN}/authZToken`;

export const AUTH0_ERROR = 'Auth0Error';

type RenewTokenCallback = (error: Auth0Error | null) => void;

export class Auth {
  private readonly clientID: string;
  private readonly returnTo: string;
  private readonly webAuth: WebAuth;

  private authenticationCheckTimeout: any = null;

  constructor(authConfig: AuthConfig) {
    const { returnTo, ...authOptions } = authConfig;
    this.clientID = authOptions.clientID;
    this.returnTo = returnTo;
    this.webAuth = new WebAuth(authOptions as AuthOptions);
  }

  private setSession(authResult: Auth0DecodedHash): void {
    const expiresAt =
      Math.min(Number(authResult.expiresIn), MAX_HOURS * SECONDS_PER_HOUR) *
        MILLISECONDS_PER_SECOND +
      Date.now();

    localStorage.setItem(ACCESS_TOKEN, authResult.accessToken as string);
    localStorage.setItem(ID_TOKEN, authResult.idToken as string);
    localStorage.setItem(EXPIRES_AT, JSON.stringify(expiresAt));
    this.scheduleAuthenticationCheck();
  }

  public async fetchUserProfile(): Promise<Auth0UserProfile> {
    return new Promise((resolve, reject) => {
      this.webAuth.client.userInfo(
        this.getAccessToken(),
        (error: Auth0Error | null, profile: Auth0UserProfile | null) => {
          if (error !== null || profile === null) {
            reject(error);
            return;
          }

          resolve(profile);
        }
      );
    });
  }

  public getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN) as string;
  }

  public getIdToken(): string {
    return localStorage.getItem(ID_TOKEN) as string;
  }

  public getTokenExpiry(): number {
    return Number(localStorage.getItem(EXPIRES_AT) as string);
  }

  public getTimeTillExpiry(): number {
    return this.getTokenExpiry() - Date.now() - RENEWAL_BUFFER;
  }

  public isAuthenticated(): boolean {
    const accessToken: string | null = localStorage.getItem(ACCESS_TOKEN);
    const idToken: string | null = localStorage.getItem(ID_TOKEN);
    const expiry: string | null = localStorage.getItem(EXPIRES_AT);
    return (
      accessToken !== null &&
      idToken !== null &&
      expiry !== null &&
      Date.now() < Number(expiry)
    );
  }

  public handleAuthenticationSuccess(
    props: RouteComponentProps,
    redirectUrlOnError: string
  ): void {
    if (/access_token|id_token|error/.test(props.location.hash)) {
      this.webAuth.parseHash(
        (error: Auth0Error | null, authResult: Auth0DecodedHash | null) => {
          if (
            !!authResult &&
            authResult.accessToken !== undefined &&
            authResult.idToken !== undefined
          ) {
            this.setSession(authResult);
            props.location.hash = '';
          } else if (error !== null) {
            console.error(error);
            if (error.errorDescription) {
              localStorage.setItem(AUTH0_ERROR, error.errorDescription);
            } else {
              localStorage.setItem(
                AUTH0_ERROR,
                'Error occurred during authentication.'
              );
            }
            props.history.replace(redirectUrlOnError);
          }
        }
      );
    }
  }

  public login(): void {
    if (!this.isAuthenticated()) {
      this.webAuth.authorize();
    }
  }

  public logout(): void {
    clearTimeout(this.authenticationCheckTimeout);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem(AUTH0_ERROR);
    localStorage.removeItem(MANAGEMENT_API_TOKEN);
    localStorage.removeItem(AUTHZ_TOKEN);
    AUTHZ.clearClaims();
    this.webAuth.logout({
      clientID: this.clientID,
      returnTo: this.returnTo
    });
  }

  public renewToken(callback: RenewTokenCallback) {
    clearTimeout(this.authenticationCheckTimeout);
    this.authenticationCheckTimeout = null;

    this.webAuth.checkSession(
      {},
      (error: Auth0Error | null, result: Auth0DecodedHash | null) => {
        if (error === null) {
          this.setSession(result as Auth0DecodedHash);
          callback(null);
        } else {
          callback(error);
        }
      }
    );
  }

  public readonly scheduleAuthenticationCheck = () => {
    if (this.authenticationCheckTimeout !== null) {
      clearTimeout(this.authenticationCheckTimeout);
      this.authenticationCheckTimeout = null;
    }

    if (this.isAuthenticated()) {
      this.authenticationCheckTimeout = setTimeout(
        this.scheduleAuthenticationCheck,
        POLL_FREQUENCY
      );
    } else {
      this.logout();
    }
  }
}
