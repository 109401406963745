const REGION: string =
  process.env.REGION !== undefined ? process.env.REGION : 'ap-southeast-2';

export enum Environments {
  DEV = 'dev',
  PROD = 'prod',
  UAT = 'uat',
  TEST = 'test'
}

export class AwsConfig {
  public get region(): string {
    return REGION;
  }
}

export interface ApiConfig {
  baseUrl: string;
}

export interface AuthConfig {
  domain: string;
  clientID: string;
  redirectUri: string;
  responseType: string;
  returnTo: string;
  scope: string;
  audience: string;
  adDomain: string;
}

export interface Auth0ManagementApiConfig {
  domain: string;
}

export interface AuthZApiConfig {
  prefixes: string[];
}

export interface StreamingConfig {
  stream1: string;
  stream2: string;
  stream3: string;
  stream4: string;
  stream5: string;
  stream6: string;
  stream7: string;
  stream8: string;
}

export interface IConfig {
  api: ApiConfig;
  auth: AuthConfig;
  auth0ManagementApi: Auth0ManagementApiConfig;
  authZApi: AuthZApiConfig;
  aws: AwsConfig;
  streaming: StreamingConfig;
  name: string;
  cloudfrontStaticContentUrl: string;
  remoteOperationsSupervisorEmails: string;
}
