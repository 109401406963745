import React from 'react';
import {
  LinkProps as RouterLinkProps,
  NavLink,
  Route,
  Switch
} from 'react-router-dom';
import Channels from '../components/admin/Channels';
import Organizations from '../components/admin/Organizations';
import Projects from '../components/admin/Projects';
import ProjectUsers from '../components/admin/ProjectUsers';
import Users from '../components/admin/Users';
import AllStreams from '../components/AllStreams';
import Callback from '../components/Callback';
import HdStream from '../components/HdStream';
import Landing from '../components/Landing';
import MainLayout from '../components/layouts/MainLayout';
import MinimalLayout from '../components/layouts/MinimalLayout';
import ProjectAssignments from '../components/ProjectAssignments';
import ProjectStreams from '../components/ProjectStreams';
import Verification from '../components/Verification';
import { PrivateRoute } from './PrivateRoute';

const root = '/';
const login = '/login';
const home = '/home';
const callback = '/callback';
const hdStream = '/streams/:id';
const verification = '/verification';
const organizations = '/organizations';
const projects = '/organizations/:organizationId/projects';
const users = '/users';
const projectUsers = '/organizations/:organizationId/projects/:projectId/users';
const projectAssignments = '/project-assignments';
const projectStreams = '/project-assignments/:projectId/streams';
const projectHdStreams = '/project-assignments/:projectId/streams/:id';
const allStreams = '/allStreams';
const channels = '/channels';

export const UiRoutes = {
  login,
  root,
  home,
  organizations,
  projects,
  projectUsers,
  users,
  projectAssignments,
  projectStreams,
  projectHdStreams,
  channels
};

export function getProjectStreamsRoute(projectId: string): string {
  return projectStreams.replace(':projectId', projectId);
}

export function getProjectHdStreamsRoute(
  projectId: string,
  id: string
): string {
  return projectStreams.replace(':projectId', projectId).replace(':id', id);
}

export function getStreamRoute(streamId: number): string {
  const streamName = getStreamName(streamId);
  return `streams/${streamName.split(' ')[1]}`;
}

export function getStreamName(streamId: number): string {
  const streamName = `Stream ${streamId}`;
  return streamName;
}

// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
export const AdapterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <NavLink innerRef={ref as any} {...props} />
);

export default function Routes(): JSX.Element {
  return (
    <Switch>
      {[root, home].map(path => (
        <PrivateRoute
          key={path}
          exact={true}
          path={path}
          layout={MainLayout}
          component={ProjectAssignments}
        />
      ))}
      {[login].map(path => (
        <MinimalLayout
          key={path}
          exact={true}
          path={path}
          component={Landing}
        />
      ))}
      <Route path={login} component={Landing} />
      <Route path={callback} component={Callback} />
      <PrivateRoute path={hdStream} component={HdStream} layout={MainLayout} />
      <PrivateRoute
        path={projectHdStreams}
        exact={true}
        component={HdStream}
        layout={MainLayout}
      />
      <PrivateRoute
        path={organizations}
        exact={true}
        component={Organizations}
        layout={MainLayout}
      />
      <PrivateRoute
        path={projects}
        exact={true}
        component={Projects}
        layout={MainLayout}
      />
      <PrivateRoute path={users} component={Users} layout={MainLayout} />
      <PrivateRoute
        path={projectUsers}
        exact={true}
        component={ProjectUsers}
        layout={MainLayout}
      />
      <PrivateRoute
        path={users}
        exact={true}
        component={Users}
        layout={MainLayout}
      />
      <PrivateRoute
        path={projectAssignments}
        exact={true}
        component={ProjectAssignments}
        layout={MainLayout}
      />
      <PrivateRoute
        path={projectStreams}
        exact={true}
        component={ProjectStreams}
        layout={MainLayout}
      />
      <PrivateRoute
        path={allStreams}
        exact={true}
        component={AllStreams}
        layout={MainLayout}
      />
      <PrivateRoute
        path={channels}
        exact={true}
        component={Channels}
        layout={MainLayout}
      />
      <MinimalLayout path={verification} component={Verification} />
    </Switch>
  );
}
