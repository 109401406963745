import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { IAssignmentsDto, IIdTokenDto } from '../../types/dto.type';
import { get } from '../api/axios';
import { authorizedStreams } from '../api/urls';
import { getStreamName, getStreamRoute } from '../routes/Routes';
import { AUTH, AUTHZ, CONFIG } from '../services';
import { getStreamUrl } from './streamInfo';

const cloudFrontPolicy = 'CloudFront-Policy';
const cloudFrontKeyPairId = 'CloudFront-Key-Pair-Id';
const cloudFrontSignature = 'CloudFront-Signature';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: '1em'
    },
    title: {
      fontSize: 14,
      marginBottom: 0
    },
    childCard: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1)
    },
    playerWrapper: {
      position: 'relative',
      paddingTop: '56.25%'
    },
    reactPlayer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    button: {
      padding: 0
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    container: {
      marginTop: '8em',
      textAlign: 'center'
    }
  })
);

export default function AllStreams(_props: RouteComponentProps): JSX.Element {
  const classes = useStyles();
  const [startPlaying, setStartPlaying] = useState(false);
  const defaultStreamIds: number[] = [];
  const [streamIds, setStreamIds] = useState(defaultStreamIds);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const decoded = jwt_decode(AUTH.getIdToken()) as IIdTokenDto;
    setEmail(decoded.email);

    const url = authorizedStreams();
    const getAuthorizedStreams = async () => {
      const isAdmin = await AUTHZ.permitAppRole('admin');

      if (isAdmin) {
        setStreamIds([1, 2, 3, 4, 5, 6, 7, 8]);
      } else {
        const assignments = (await get(url)) as IAssignmentsDto[];
        let result: number[] = [];
        if (assignments) {
          for (const assignment of assignments) {
            result = result.concat(assignment.streams);
          }
          const authorizedStreamIds = result.reduce(
            (unique: number[], item: number) => {
              return unique.includes(item) ? unique : [...unique, item];
            },
            []
          );
          setStreamIds(authorizedStreamIds);
        }
      }
      setLoading(false);
    };
    getAuthorizedStreams();
  }, []);

  useEffect(() => {
    let cookiesSet = 0;
    const fetchSignedCookie = async () => {
      for (const streamId of streamIds) {
        const cookieDomain = CONFIG.api.baseUrl.split('//')[1];
        const url = CONFIG.api.baseUrl + `/api/signedcookie/${streamId}`;
        const response = await get(url);
        Cookies.set(cloudFrontPolicy, response[cloudFrontPolicy], {
          path: `/channel-${streamId}/`,
          domain: cookieDomain
        });
        Cookies.set(cloudFrontKeyPairId, response[cloudFrontKeyPairId], {
          path: `/channel-${streamId}/`,
          domain: cookieDomain
        });
        Cookies.set(cloudFrontSignature, response[cloudFrontSignature], {
          path: `/channel-${streamId}/`,
          domain: cookieDomain
        });
        cookiesSet++;
      }
      if (cookiesSet === streamIds.length) {
        setStartPlaying(true);
      }
    };

    if (
      process.env.REACT_APP_ENVIRONMENT === undefined ||
      process.env.REACT_APP_ENVIRONMENT.toLocaleLowerCase() !== 'local'
    ) {
      fetchSignedCookie();
    } else {
      setStartPlaying(true);
    }
  }, [streamIds]);

  if (!startPlaying || loading) {
    return <CircularProgress size={40} className={classes.progress} />;
  }

  if (streamIds.length === 0) {
    return (
      <Container className={classes.container}>
        <Typography variant='h6' gutterBottom={true}>
          The user {email} does not have permission to view any project live
          streams, or there are no projects configured. If you require streams
          access, please send an email to your remote operations supervisor.
        </Typography>
      </Container>
    );
  }

  return (
    <Grid className={classes.root} container={true} spacing={2}>
      {streamIds.map(streamId => (
        <Grid key={getStreamName(streamId)} item={true} xs={12} md={3} sm={12}>
          <Card className={classes.childCard}>
            <Typography
              className={classes.title}
              color='textSecondary'
              gutterBottom={true}
            >
              {getStreamName(streamId)}
            </Typography>
            <div className={classes.playerWrapper}>
              <ReactPlayer
                className={classes.reactPlayer}
                url={getStreamUrl(getStreamName(streamId), false)}
                playing={startPlaying}
                volume={0}
                width='100%'
                height='100%'
                config={{
                  file: {
                    forceHLS: true,
                    hlsVersion: '0.13.2',
                    hlsOptions: {
                      liveBackBufferLength: 0,
                      xhrSetup(xhr: any, _url: string) {
                        xhr.withCredentials =
                          process.env.REACT_APP_ENVIRONMENT !== 'local'; // send cookies
                      }
                    }
                  }
                }}
              />
            </div>
            <CardActions className={classes.button}>
              <Button
                size='small'
                component={RouterLink}
                to={getStreamRoute(streamId)}
              >
                HD Stream
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
