import { makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  IAssignmentDto,
  IOrganizationDto,
  IUserDto
} from '../../../types/dto.type';
import { resetPassword } from '../../api/auth0.api';
import { deleteResource, get } from '../../api/axios';
import {
  allOrganizationsUrl,
  deleteAuth0UserUrl,
  projectsAssignedUrl,
  unassignUserUrl,
  usersUrl,
  userUrl
} from '../../common/adminUrls';
import { CONFIG } from '../../services';
import ConfirmationDialog from './ConfirmationDialog';
import { IUserInfo, UserInfo } from './userInfo';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  table: {
    marginTop: theme.spacing(2)
  }
}));

export default function Users(): JSX.Element {
  const snackbar = useSnackbar();
  const defaultUsersState: IUserInfo[] = [];
  const [users, setUsers] = useState(defaultUsersState);

  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const [userToModify, setUserToModify] = useState<IUserInfo>();
  const [deleteUserBusy, setDeleteUserBusy] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data: IUserInfo[] = [];

    const fetchData = async () => {
      let url = allOrganizationsUrl();
      const organizationDtos = (await get(url)) as IOrganizationDto[];
      for (const organizationDto of organizationDtos) {
        url = usersUrl(organizationDto.id);
        const userDtos = (await get(url)) as IUserDto[];
        for (const userDto of userDtos) {
          const userInfo = new UserInfo(
            userDto,
            organizationDto.id,
            organizationDto.name
          );
          data.push(userInfo);
        }
      }
      setUsers(data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const handleCloseDeleteUserDialog = () => {
    setOpenDeleteUserDialog(false);
    setDeleteUserBusy(false);
  };

  const deleteUserOnClick = (row: IUserInfo | IUserInfo[]): void => {
    if (row) {
      const userInfo = row as IUserInfo;
      if (userInfo) {
        setUserToModify(userInfo);
        setOpenDeleteUserDialog(true);
      }
    }
  };

  const resetUserPasswordOnClick = async (
    row: IUserInfo | IUserInfo[]
  ): Promise<void> => {
    if (row) {
      const userInfo = row as IUserInfo;
      if (userInfo) {
        setUserToModify(userInfo);
        await resetUserPassword(userInfo);
      }
    }
  };

  const resetUserPassword = async (user: IUserInfo) => {
    try {
      const response = await resetPassword(user.email);
      if (response.status === 200) {
        snackbar.enqueueSnackbar(
          `An email with instructions to reset password has been sent to ${user.email}`,
          {
            variant: 'info'
          }
        );
      }
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  const handleDeleteUser = async () => {
    try {
      setDeleteUserBusy(true);
      if (userToModify) {
        // Delete user from auth0
        await deleteResource(deleteAuth0UserUrl(userToModify.email));
        const assignments = (await get(
          projectsAssignedUrl(userToModify.id)
        )) as IAssignmentDto[];

        // Unassign user's projects
        for (const assignment of assignments) {
          const projectId = assignment.PK.split('#')[3];
          await deleteResource(unassignUserUrl(), {
            organizationId: userToModify.organizationId,
            userId: userToModify.id,
            projectId
          });
        }

        // Delete user from our ddb
        const url = userUrl(userToModify.organizationId, userToModify.id);
        const response = await deleteResource(url);
        if (response && response.status === 200) {
          setUsers(users.filter(x => x.id !== userToModify.id));
          setDeleteUserBusy(false);
          snackbar.enqueueSnackbar(
            `User ${userToModify.email} successfully deleted`,
            {
              variant: 'success'
            }
          );
        }
      }
      handleCloseDeleteUserDialog();
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  const isAdUser = (user: IUserInfo): boolean => {
    const emailParts = user.email.split('@');
    return emailParts[1] === CONFIG.auth.adDomain;
  };

  const classes = useStyles();
  return (
    <div>
      <div className={classes.table}>
        <MaterialTable
          isLoading={isLoading}
          title='Users'
          columns={[
            {
              title: 'Email',
              field: 'email'
            },
            {
              title: 'Organization',
              field: 'organization'
            },
            {
              title: 'Projects assigned',
              field: 'projects'
            }
          ]}
          data={users}
          actions={[
            {
              icon: 'delete_forever',
              tooltip: 'Delete user',
              onClick: (_event, row) => deleteUserOnClick(row)
            },
            rowData => {
              return {
                icon: 'lock_open',
                tooltip: isAdUser(rowData)
                  ? `@${CONFIG.auth.adDomain} domain users does not need password`
                  : 'Reset user password',
                onClick: (_event, row) => resetUserPasswordOnClick(row),
                disabled: isAdUser(rowData)
              };
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10
          }}
        />
      </div>
      <ConfirmationDialog
        open={openDeleteUserDialog}
        title={`Are you sure you want to delete ${
          userToModify === undefined ? 'user' : userToModify.email
        }?`}
        onClose={handleCloseDeleteUserDialog}
        message={`Deleting an user will revoke all the access to the site, this action cannot be undone.`}
        yesHandler={handleDeleteUser}
        yesButtonDisabled={userToModify === undefined}
        noHandler={handleCloseDeleteUserDialog}
        loading={deleteUserBusy}
      />
    </div>
  );
}
