import { CssBaseline, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import { AUTH } from '../../services';
import { RenderAppBar } from './AppBar';
import AppBreadcrumbs from './AppBreadcrumbs';
import { RenderDrawer } from './Drawer';
import Footer from './Footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    main: {
      flexGrow: 1
    },
    content: {
      margin: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2)
      }
    },
    appContainer: {
      display: 'contents'
    },
    appBarSpacer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar
    },
    breadcrumbs: {
      marginBottom: theme.spacing(0)
    }
  })
);

export default function MainLayout({ children }: RouteProps) {
  const classes = useStyles();

  return (
    <div className={classes.appContainer}>
      <div className={classes.root + ' content'}>
        <CssBaseline />
        <RenderAppBar />
        <RenderDrawer />
        <div className={classes.main}>
          <div className={classes.appBarSpacer} />
          <div className={classes.content}>
            {AUTH.isAuthenticated()}
            <div className={classes.breadcrumbs}>
              <AppBreadcrumbs />
            </div>
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
