import {
  CircularProgress,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IAssignmentsDto, IIdTokenDto } from '../../types/dto.type';
import { get } from '../api/axios';
import { authorizedStreams } from '../api/urls';
import { AUTH } from '../services';
import ProjectAssignmentCard from './ProjectAssignmentCard';
import { useStateWithLocalStorage } from './toolbox';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      marginTop: '1em'
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    container: {
      marginTop: '8em',
      textAlign: 'center'
    }
  })
);

export default function ProjectAssignments(
  _props: RouteComponentProps
): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const defaultAssignments: IAssignmentsDto[] = [];
  const [assignments, setAssignments] = useState(defaultAssignments);
  const [videoLayout, setVideoLayout] = useStateWithLocalStorage('VideoLayout');

  useEffect(() => {
    // remove all projects from VideoLayout Local Storage that no longer exist
    const cleanLayoutState = (assignmentDtos: IAssignmentsDto[]) => {
      if (videoLayout) {
        const sessionLayout = JSON.parse(videoLayout);

        for (const [key] of Object.entries(sessionLayout)) {
          if (!assignmentDtos.some(item => item.projectId === key)) {
            delete sessionLayout[key];
          }
        }

        setVideoLayout(JSON.stringify(sessionLayout));
      }
    };

    const decoded = jwt_decode(AUTH.getIdToken()) as IIdTokenDto;
    setEmail(decoded.email);

    const url = authorizedStreams();
    const getAuthorizedStreams = async () => {
      const assignmentDtos = (await get(url)) as IAssignmentsDto[];
      setAssignments(assignmentDtos);
      setLoading(false);

      cleanLayoutState(assignmentDtos);
    };
    getAuthorizedStreams();
  }, [videoLayout, setVideoLayout]);

  if (loading) {
    return <CircularProgress size={40} className={classes.progress} />;
  }

  if (!assignments || assignments.length === 0) {
    return (
      <Container className={classes.container}>
        <Typography variant='h6' gutterBottom={true}>
          The user {email} does not have permission to view any project live
          streams, or there are no projects configured. If you require streams
          access, please send an email to your remote operations supervisor.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth='lg'>
      <Grid className={classes.root} container={true} spacing={4}>
        {assignments.map(assignment => (
          <Grid key={assignment.projectId} item={true} xs={12} md={4} sm={12}>
            <ProjectAssignmentCard {...assignment} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
