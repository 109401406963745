import { CONFIG } from '../services';

export interface StreamInfo {
  name: string;
  url: string;
}

const streams: StreamInfo[] = [
  { name: '1', url: CONFIG.streaming.stream1 },
  { name: '2', url: CONFIG.streaming.stream2 },
  { name: '3', url: CONFIG.streaming.stream3 },
  { name: '4', url: CONFIG.streaming.stream4 },
  { name: '5', url: CONFIG.streaming.stream5 },
  { name: '6', url: CONFIG.streaming.stream6 },
  { name: '7', url: CONFIG.streaming.stream7 },
  { name: '8', url: CONFIG.streaming.stream8 }
];

export function getStreamBaseUrl(id: string): string {
  const streamInfo = streams.filter((x) => x.name === id)[0];
  return streamInfo.url;
}

// Note that the stream id is expected to be 1 to 8
export function getStreamUrl(id: string, hd: boolean): string {
  const url = getStreamBaseUrl(id);
  if (url) {
    if (hd) {
      return url + 'hda/main.m3u8';
    }
    return url + 'sda/main.m3u8';
  }
  // If url is empty ReactPlayer will append localhost:3000 in front of /sda/main.m3u8
  return '';
}
