import {
  Drawer,
  IconButton,
  List,
  ListItem,
  // stupid fix-on-save keeps formatting into one line, this is a hacky work-around
  ListItemIcon,
  ListItemText,
  Theme,
  useTheme
} from '@material-ui/core';
import {
  Business as BusinessIcon,
  ChevronLeft,
  ChevronRight,
  Home,
  LiveTv,
  People as PeopleIcon
} from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { AdapterLink, UiRoutes } from '../../routes/Routes';
import { AUTH } from '../../services';
import { AUTHZ } from '../../services/index';
import { useGlobalState } from '../../store/globalState';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuLinkText: {
      fontSize: '1.3em'
    },
    menuLinks: {
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 0.54)',
      '& path': {
        color: 'rgba(0, 0, 0, 0.54)'
      },
      '&.active': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '& path': {
          color: 'white'
        },
        '& span': {
          fontWeight: 10
        }
      }
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7) + 1
      }
    },
    appBarSpacer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  })
);

export function RenderDrawer(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useGlobalState('globalOpenDrawer');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // authZ
    const fetch = async () => {
      if (AUTH.isAuthenticated()) {
        setIsAdmin(await AUTHZ.permitAppRole('admin'));
      }
    };

    fetch();
  }, []);

  function handleDrawerClose() {
    setOpenDrawer(false);
  }

  const routes = [
    {
      path: UiRoutes.home,
      title: 'Home',
      icon: () => <Home />
    }
  ];

  if (isAdmin) {
    routes.push(
      {
        path: UiRoutes.organizations,
        title: 'Organizations',
        icon: () => <BusinessIcon />
      },
      {
        path: UiRoutes.users,
        title: 'Users',
        icon: () => <PeopleIcon />
      },
      {
        path: UiRoutes.channels,
        title: 'Channels',
        icon: () => <LiveTv />
      }
    );
  }

  if (AUTH.isAuthenticated()) {
    return (
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer
          })
        }}
        open={openDrawer}
      >
        <div className={classes.appBarSpacer}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <List>
          {routes.map((route, index) => (
            <ListItem
              key={index}
              className={classes.menuLinks}
              button={true}
              component={AdapterLink}
              to={route.path}
            >
              <ListItemIcon>{route.icon()}</ListItemIcon>{' '}
              <ListItemText
                primary={route.title}
                classes={{ primary: classes.menuLinkText }}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    );
  }
  return <div />;
}
