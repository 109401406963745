import { Button } from '@material-ui/core';
import React from 'react';
import { AUTH } from '../services';

export default function Login(): JSX.Element {
  function handleLogin(): void {
    AUTH.login();
  }

  return (
    <Button color='inherit' onClick={handleLogin}>
      Login
    </Button>
  );
}
