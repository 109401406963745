import { Container, createStyles, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import {
  IdList,
  LayoutProps,
  sessionStreamAssignment
} from './BaseVideoLayout';
import { VideoLayout } from './ProjectStreams';
import Stream from './Stream';
import useCalculatedWidth from './useCalculatedWidth';

const useStyles = makeStyles(() =>
  createStyles({
    root: {}
  })
);

enum VideoPosition {
  pos11,
  pos12,
  pos21,
  pos22
}

export const widthAdj = 110;
export const heightAdj = 300;
export const videoRatio = 1.75;

export default function VideoLayout2x2(props: LayoutProps): JSX.Element {
  const classes = useStyles();
  const streamIds = props.streamIds;
  const projectId = props.projectId;
  const videoLayoutCapacity = 4;

  const defaultStreamAssignment = {
    pos11: streamIds.length > 0 ? streamIds[0] : 0,
    pos12: streamIds.length > 1 ? streamIds[1] : 0,
    pos21: streamIds.length > 2 ? streamIds[2] : 0,
    pos22: streamIds.length > 3 ? streamIds[3] : 0
  };

  const getStreamId = (layoutPosition: VideoPosition): number => {
    const streamAssignment = sessionStreamAssignment(
      props.videoLayout,
      projectId,
      streamIds,
      defaultStreamAssignment,
      props.setVideoLayout,
      VideoLayout.layout2x2,
      videoLayoutCapacity
    );

    switch (layoutPosition) {
      case VideoPosition.pos11:
        return streamAssignment.pos11;
      case VideoPosition.pos12:
        return streamAssignment.pos12;
      case VideoPosition.pos21:
        return streamAssignment.pos21;
      case VideoPosition.pos22:
        return streamAssignment.pos22;
    }
  };

  const videoContainer = (pos: VideoPosition) => {
    return getStreamId(pos) ? (
      <Stream
        streamId={getStreamId(pos)}
        pos={VideoPosition[pos]}
        streamIds={streamIds}
        projectId={projectId}
        setVideoLayout={props.setVideoLayout}
        videoLayout={props.videoLayout}
        layoutName={VideoLayout[VideoLayout.layout2x2]}
        streamNames={props.streamNames as IdList}
        propogateStreamNameChange={props.propogateStreamNameChange}
      />
    ) : null;
  };

  const width = useCalculatedWidth(110, 300, 1.75);

  return (
    <Container style={{ maxWidth: width }}>
      <Grid className={classes.root} container={true} spacing={2}>
        <Grid key={1} item={true} xs={12} md={6} sm={12}>
          {videoContainer(VideoPosition.pos11)}
        </Grid>
        <Grid key={2} item={true} xs={12} md={6} sm={12}>
          {videoContainer(VideoPosition.pos12)}
        </Grid>
        <Grid key={3} item={true} xs={12} md={6} sm={12}>
          {videoContainer(VideoPosition.pos21)}
        </Grid>
        <Grid key={4} item={true} xs={12} md={6} sm={12}>
          {videoContainer(VideoPosition.pos22)}
        </Grid>
      </Grid>
    </Container>
  );
}
