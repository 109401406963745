import {
  Container,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { LastPage } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { RouteComponentProps } from 'react-router-dom';
import { get } from '../api/axios';
import { streamNameUrl } from '../api/urls';
import { getStreamName } from '../routes/Routes';
import { getStreamUrl } from './streamInfo';
import useCalculatedWidth from './useCalculatedWidth';

const segmentLength = 1;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
    playerWrapper: {
      position: 'relative',
      paddingTop: '56.25%'
    },
    reactPlayer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    spacer: {
      flexGrow: 1
    },
    button: {
      padding: 0
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: theme.spacing(1)
    }
  })
);

interface StreamId {
  id: string;
}

export default function HdStream(
  props: RouteComponentProps<StreamId>
): JSX.Element {
  const classes = useStyles();
  const playerRef = useRef<ReactPlayer | null>(null);

  const streamId = props.match.params.id;
  const [streamName, setStreamName] = useState(
    getStreamName(parseInt(streamId, 10))
  );

  useEffect(
    () => {
      const fetchStreamName = async () => {
        const streamNameResponse = await get(streamNameUrl(streamId));
        if (streamNameResponse) {
          setStreamName(streamNameResponse.name);
        }
      };
      fetchStreamName();
    },
    [streamId]
  );

  const handleSeekLatest = (): void => {
    if (playerRef.current) {
      const player = playerRef.current;
      const duration = player.getDuration();
      player.seekTo(duration - segmentLength);
    }
  };

  const width = useCalculatedWidth(128, 210, 1.75);

  return (
    <Container style={{ maxWidth: width }} className={classes.root}>
      <div className={classes.playerWrapper}>
        <ReactPlayer
          ref={playerRef}
          className={classes.reactPlayer}
          url={getStreamUrl(streamId, true)}
          controls={true}
          playing={true}
          volume={0}
          muted={true}
          width='100%'
          height='100%'
          playsinline={true}
          config={{
            file: {
              hlsVersion: '0.13.2',
              hlsOptions: {
                liveBackBufferLength: 1,
                xhrSetup(xhr: any, _url: string) {
                  xhr.withCredentials =
                    process.env.REACT_APP_ENVIRONMENT !== 'local'; // send cookies
                }
              }
            }
          }}
        />
      </div>
      <div className={classes.row}>
        <Typography variant='h6'>{streamName}</Typography>
        <span className={classes.spacer} />
        <Tooltip title='Seek to latest'>
          <IconButton color='primary' onClick={handleSeekLatest}>
            <LastPage />
          </IconButton>
        </Tooltip>
      </div>
    </Container>
  );
}
