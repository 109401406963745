import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { RenderAppBar } from './AppBar';
import Footer from './Footer';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    content: {
      flex: '1 0 auto'
    },
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

export default function EmptyLayout({ component, ...rest }: any) {
  const classes = useStyles();

  const renderEmptyLayout: any = (props: RouteComponentProps<any>) => (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <RenderAppBar />
        <Comp {...props} />
      </div>
      <Footer />
    </div>
  );

  const Comp = component;
  return <Route {...rest} render={renderEmptyLayout} />;
}
