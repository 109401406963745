import { IAuthZExtendClaimsDto } from './dto.type';

export interface IAuthZClaim {
  [user: string]: string[];
}

export class AuthZClaim implements IAuthZClaim {
  [user: string]: string[];

  constructor(dto: IAuthZExtendClaimsDto, user: string) {
    this[user] = [];
    for (const key of Object.keys(dto)) {
      this[user].push(key);
    }
  }

  public static deserialize(
    dto: IAuthZExtendClaimsDto,
    user: string
  ): AuthZClaim {
    return new AuthZClaim(dto, user);
  }
}
