import axios from 'axios';
import { changePasswordUrlV2 } from '../common/auth0Urls';

export const CONNECTION = 'Username-Password-Authentication';

export const resetPassword = async (email: string): Promise<any> => {
  const url = changePasswordUrlV2();
  const postBody = {
    email,
    connection: CONNECTION
  };
  return axios.post(url, postBody);
};
