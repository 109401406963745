import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { UiRoutes } from '../routes/Routes';
import { AUTH, AUTHZ } from '../services';

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}));

export default function Callback(props: RouteComponentProps): JSX.Element {
  useEffect(() => {
    const handleLogin = async () => {
      if (!AUTH.isAuthenticated()) {
        AUTH.handleAuthenticationSuccess(props, UiRoutes.login);
      }
    };
    const getClaims = async () => {
      if (AUTH.isAuthenticated()) {
        await AUTHZ.setAuthZClaims();
        props.history.replace(UiRoutes.home);
      } else {
        setTimeout(getClaims, 500);
      }
    };
    handleLogin();
    getClaims();
  });

  const classes = useStyles();
  return <CircularProgress size={40} className={classes.progress} />;
}
