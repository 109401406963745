import { Button, makeStyles, TextField } from '@material-ui/core';
import _ from 'lodash';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { IOrganizationDto } from '../../../types/dto.type';
import { deleteResource, get, post, put } from '../../api/axios';
import { allOrganizationsUrl, organizationUrl } from '../../common/adminUrls';
import history from '../../History';
import ConfirmationDialog from './ConfirmationDialog';
import GenericDialog from './GenericDialog';

interface IOrganizationInfo {
  id: string;
  name: string;
  numberOfProjects: number;
  numberOfUsers: number;
}

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  table: {
    marginTop: theme.spacing(2)
  }
}));

export default function Organizations(): JSX.Element {
  const snackbar = useSnackbar();
  const defaultAddOrganizationState: IOrganizationInfo[] = [];
  const [organizations, setOrganizations] = useState(
    defaultAddOrganizationState
  );
  const [openAddOrganizationDialog, setOpenAddOrganizationDialog] = useState(
    false
  );
  const [
    openDeleteOrganizationDialog,
    setOpenDeleteOrganizationDialog
  ] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState<
    IOrganizationInfo
  >();

  const [openEditOrganizationDialog, setOpenEditOrganizationDialog] = useState(
    false
  );
  const [organizationToEdit, setOrganizationToEdit] = useState<
    IOrganizationInfo
  >();

  const [organizationName, setOrganizationName] = useState('');
  const [textFieldError, setTextFieldError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data: IOrganizationInfo[] = [];
    const fetchData = async () => {
      const url = allOrganizationsUrl();
      const dtos = (await get(url)) as IOrganizationDto[];
      for (const dto of dtos) {
        data.push({
          id: dto.id,
          name: dto.name,
          numberOfProjects: !dto.numberOfProjects ? 0 : dto.numberOfProjects,
          numberOfUsers: !dto.numberOfUsers ? 0 : dto.numberOfUsers
        });
      }
      setOrganizations(data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const selectOrganization = (
    _event: React.MouseEvent | undefined,
    row: IOrganizationInfo | undefined
  ): void => {
    if (row) {
      history.push(`/organizations/${row.id}/projects/`);
    }
  };

  const handleOpenAddOrganizationDialog = () => {
    setOpenAddOrganizationDialog(true);
  };

  const handleCloseAddOrganizationDialog = () => {
    setOpenAddOrganizationDialog(false);
    setTextFieldError('');
  };

  const organizationNameExists = (value: string): boolean => {
    const names = organizations.map(({ name }) => name.toLowerCase());
    return names.includes(value.trim().toLowerCase());
  };

  const handleOrganizationNameChange = (e: any) => {
    // Check if organization with the same name already exist
    if (organizationNameExists(e.target.value)) {
      setTextFieldError('Organization with the same name already exist');
    } else {
      setTextFieldError('');
    }
    setOrganizationName(e.target.value);
  };

  const handleAddOrganization = async () => {
    try {
      const url = allOrganizationsUrl();
      const dto = (await post(url, {
        name: organizationName
      })) as IOrganizationDto;

      setOrganizations([
        ...organizations,
        {
          id: dto.id,
          name: dto.name,
          numberOfProjects: 0,
          numberOfUsers: 0
        }
      ]);

      setOpenAddOrganizationDialog(false);
      snackbar.enqueueSnackbar(
        `Organization ${organizationName} successfully created`,
        {
          variant: 'success'
        }
      );
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  const handleCloseDeleteOrganizationDialog = () => {
    setOpenDeleteOrganizationDialog(false);
  };

  const deleteOrganizationOnClick = (
    row: IOrganizationInfo | IOrganizationInfo[]
  ): void => {
    if (row) {
      const organizationInfo = row as IOrganizationInfo;
      if (organizationInfo) {
        setOrganizationToDelete(organizationInfo);
        setOpenDeleteOrganizationDialog(true);
      }
    }
  };

  const handleDeleteOrganization = async () => {
    try {
      if (organizationToDelete) {
        const id = organizationToDelete.id;
        const url = organizationUrl(id);
        const response = await deleteResource(url);
        if (response && response.status === 200) {
          setOrganizations(organizations.filter(x => x.id !== id));
        }
      }
      handleCloseDeleteOrganizationDialog();
      snackbar.enqueueSnackbar(
        `Organization ${organizationName} successfully deleted`,
        {
          variant: 'success'
        }
      );
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  const editOrganizationOnClick = (
    row: IOrganizationInfo | IOrganizationInfo[]
  ): void => {
    if (row) {
      const organizationInfo = row as IOrganizationInfo;
      if (organizationInfo) {
        setOrganizationToEdit(organizationInfo);
        setOpenEditOrganizationDialog(true);
      }
    }
  };

  const handleCloseEditOrganizationDialog = () => {
    setOpenEditOrganizationDialog(false);
    setTextFieldError('');
  };

  const handleEditOrganization = async () => {
    if (organizationToEdit) {
      const id = organizationToEdit.id;
      const url = organizationUrl(id);
      const response = await put(url, {
        name: organizationName
      });
      if (response && response.status === 200) {
        organizationToEdit.name = organizationName;
        const index = _.findIndex(organizations, { id });
        organizations.splice(index, 1, organizationToEdit);
      }
    }
    handleCloseEditOrganizationDialog();
  };

  const getEditedOrganizationName = () => {
    return organizationToEdit === undefined ? '' : organizationToEdit.name;
  };

  const deleteOrganizationDisabled = () => {
    return (
      organizationToDelete === undefined ||
      organizationToDelete.numberOfUsers > 0 ||
      organizationToDelete.numberOfProjects > 0
    );
  };

  const classes = useStyles();

  return (
    <div>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color='primary'
          variant='contained'
          onClick={handleOpenAddOrganizationDialog}
        >
          Add Organization
        </Button>
      </div>
      <div className={classes.table}>
        <MaterialTable
          isLoading={isLoading}
          title='Organizations'
          columns={[
            {
              title: 'Name',
              field: 'name'
            },
            {
              title: 'Number of projects',
              field: 'numberOfProjects',
              type: 'numeric'
            },
            {
              title: 'Number of users',
              field: 'numberOfUsers',
              type: 'numeric'
            }
          ]}
          data={organizations}
          onRowClick={selectOrganization}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit organization',
              onClick: (_event, row) => editOrganizationOnClick(row)
            },
            {
              icon: 'delete_forever',
              tooltip: 'Delete organization',
              onClick: (_event, row) => deleteOrganizationOnClick(row)
            }
          ]}
          options={{
            actionsColumnIndex: -1
          }}
        />
      </div>
      <GenericDialog
        open={openAddOrganizationDialog}
        title={'Add organization'}
        message={'To add an organization, please enter the name here.'}
        onClose={handleCloseAddOrganizationDialog}
        okButtonText={'Add'}
        okButtonDisabled={
          organizationName.length === 0 || textFieldError.length !== 0
        }
        okHandler={handleAddOrganization}
        cancelHandler={handleCloseAddOrganizationDialog}
      >
        <TextField
          autoFocus={true}
          error={textFieldError.length !== 0}
          helperText={textFieldError}
          margin='dense'
          id='name'
          label='Organization name'
          onChange={handleOrganizationNameChange}
          type='text'
          fullWidth={true}
        />
      </GenericDialog>
      <ConfirmationDialog
        open={openDeleteOrganizationDialog}
        title={`Are you sure you want to delete organization ${organizationToDelete ===
        undefined
          ? 'this organization'
          : organizationToDelete.name}?`}
        onClose={handleCloseDeleteOrganizationDialog}
        message={
          deleteOrganizationDisabled() ? (
            'Unable to delete organization if there are still projects or users assigned.'
          ) : (
            'This action cannot be undone.'
          )
        }
        yesHandler={handleDeleteOrganization}
        yesButtonDisabled={deleteOrganizationDisabled()}
        noHandler={handleCloseDeleteOrganizationDialog}
      />
      <GenericDialog
        open={openEditOrganizationDialog}
        title={`Edit organization ${getEditedOrganizationName()}`}
        message={'To edit an organization, please enter the name here.'}
        onClose={handleCloseEditOrganizationDialog}
        okButtonText={'Ok'}
        okButtonDisabled={
          organizationName.length === 0 || textFieldError.length !== 0
        }
        okHandler={handleEditOrganization}
        cancelHandler={handleCloseEditOrganizationDialog}
      >
        <TextField
          autoFocus={true}
          error={textFieldError.length !== 0}
          helperText={textFieldError}
          margin='dense'
          id='name'
          label='Organization Name'
          onChange={handleOrganizationNameChange}
          type='text'
          fullWidth={true}
        />
      </GenericDialog>
    </div>
  );
}
