import { IUserDto } from '../../../types/dto.type';

export interface IUserInfo {
  id: string;
  organizationId: string;
  name: string;
  email: string;
  organization: string;
  numberOfProjects: number;
  auth0UserId: string;
  projects: string;
}

const NO_PROJECTS_ASSIGNED = 'No projects assigned';

export class UserInfo implements IUserInfo {
  public readonly id: string;
  public readonly organizationId: string;
  public readonly name: string;
  public readonly email: string;
  public readonly organization: string;
  public readonly numberOfProjects: number;
  public readonly auth0UserId: string;
  public readonly projects: string;

  constructor(
    userDto: IUserDto,
    organizationId: string,
    organizationName: string
  ) {
    this.id = userDto.id;
    this.organizationId = organizationId;
    this.name = userDto.name;
    this.email = userDto.email;
    this.organization = organizationName;
    this.numberOfProjects = this.parseNumberOfProjects(userDto.projects);
    this.auth0UserId = userDto.auth0UserId;
    this.projects = this.parseProjects(userDto.projects);
  }

  parseNumberOfProjects = (projects: string): number => {
    if (!projects) {
      return 0;
    }
    const splitted = projects.split('#');
    if (splitted.length === 1 && splitted.includes('unassigned')) {
      return 0;
    }
    return splitted.length;
  }

  parseProjects = (projects: string): string => {
    if (!projects) {
      return NO_PROJECTS_ASSIGNED;
    }
    const splitted = projects.split('#');
    if (splitted.length === 1 && splitted.includes('unassigned')) {
      return NO_PROJECTS_ASSIGNED;
    }
    return splitted.join(', ');
  }
}
