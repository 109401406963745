import { Container, createStyles, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import {
  IdList,
  LayoutProps,
  sessionStreamAssignment
} from './BaseVideoLayout';
import { VideoLayout } from './ProjectStreams';
import Stream from './Stream';
import useCalculatedWidth from './useCalculatedWidth';

const useStyles = makeStyles(() =>
  createStyles({
    root: {}
  })
);

enum VideoPosition {
  pos11
}

export default function VideoLayout1x1(props: LayoutProps): JSX.Element {
  const classes = useStyles();
  const streamIds = props.streamIds;
  const projectId = props.projectId;
  const videoLayoutCapacity = 1;

  const defaultStreamAssignment = {
    pos11: streamIds.length > 0 ? streamIds[0] : 0
  };

  const getStreamId = (layoutPosition: VideoPosition): number => {
    const streamAssignment = sessionStreamAssignment(
      props.videoLayout,
      projectId,
      streamIds,
      defaultStreamAssignment,
      props.setVideoLayout,
      VideoLayout.layout1x1,
      videoLayoutCapacity
    );

    switch (layoutPosition) {
      case VideoPosition.pos11:
        return streamAssignment.pos11;
    }
  };

  const videoContainer = (pos: VideoPosition) => {
    return getStreamId(pos) ? (
      <Stream
        streamId={getStreamId(pos)}
        pos={VideoPosition[pos]}
        streamIds={streamIds}
        projectId={projectId}
        setVideoLayout={props.setVideoLayout}
        videoLayout={props.videoLayout}
        layoutName={VideoLayout[VideoLayout.layout1x1]}
        streamNames={props.streamNames as IdList}
        propogateStreamNameChange={props.propogateStreamNameChange}
      />
    ) : null;
  };

  const width = useCalculatedWidth(110, 250, 1.75);

  return (
    <Container style={{ maxWidth: width }}>
      <Grid className={classes.root} container={true} spacing={2}>
        <Grid key={1} item={true} xs={12} md={12} sm={12}>
          {videoContainer(VideoPosition.pos11)}
        </Grid>
      </Grid>
    </Container>
  );
}
