import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './index.css';
import Routes from './routes/Routes';

export default function SeedApp(_props: RouteComponentProps) {
  return <Routes />;
}

export const App = withRouter(_props => <SeedApp {..._props} />);
