import axios, { AxiosResponse, Method } from 'axios';
import { AUTH } from '../services';

export async function get(url: string, token?: string): Promise<any> {
  const response = await axiosRequest(url, 'get', {}, token);
  if (response !== null && response.status === 200) {
    return response.data;
  }
}

export async function deleteResource(
  url: string,
  body?: any,
  token?: string
): Promise<AxiosResponse | null> {
  if (!body) {
    body = {};
  }
  return axiosRequest(url, 'delete', body, token);
}

export async function put(
  url: string,
  body: any,
  token?: string
): Promise<AxiosResponse | null> {
  return axiosRequest(url, 'put', body, token);
}

export async function post(
  url: string,
  body: any,
  token?: string
): Promise<any> {
  const response = await axiosRequest(url, 'post', body, token);
  if (response !== null && response.status === 200) {
    return response.data;
  }
}

export async function axiosRequest(
  url: string,
  method: Method,
  body: any,
  bearer?: string,
  headers?: any
): Promise<AxiosResponse | null> {
  try {
    if (!headers) {
      if (!bearer) {
        bearer = AUTH.getAccessToken();
      }
      headers = {
        Authorization: `Bearer ${bearer}`,
        Identity: `${AUTH.getIdToken()}`,
      };
    }
    const signal = axios.CancelToken.source();
    const startTime = new Date().getTime();
    const response = await axios({
      method,
      url,
      cancelToken: signal.token,
      headers,
      data: body
    });
    const data = response.data;
    if (process.env.NODE_ENV !== 'production') {
      console.log(
        `Response of request ${method} received from ${url}, took ${(new Date().getTime() - startTime) / 1000
        } seconds (${response.status})`
      );
      if (response.status !== 204) {
        console.log('Response', data);
      }
    }
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request is being cancelled
      return null;
    } else {
      console.error(`Error occurred requesting data from ${url}`, error);
      throw error;
    }
  }
}
