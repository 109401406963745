import { Button, makeStyles, TextField } from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  IAssignmentDto,
  IOrganizationDto,
  IProjectDto,
  IUserDto
} from '../../../types/dto.type';
import { deleteResource, get, post, put } from '../../api/axios';
import {
  allOrganizationsUrl,
  assignUserUrl,
  auth0UsersUrl,
  auth0UsersWithEmailUrl,
  authZAdminsUrl,
  organizationUrl,
  projectUrl,
  unassignUserUrl,
  usersAssignedUrl,
  usersUrl,
  welcomeEmailUrl
} from '../../common/adminUrls';
import { IAuthZClaim } from '../../common/authZ.type';
import { CONFIG } from '../../services';
import ConfirmationDialog from './ConfirmationDialog';
import GenericDialog from './GenericDialog';
import { IUserInfo, UserInfo } from './userInfo';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  table: {
    marginTop: theme.spacing(2)
  }
}));

export interface IProjectUsersProps {
  organizationId: string;
  projectId: string;
}

export interface IProjectUserInfo {
  id: string;
  name: string;
  email: string;
  dateOfJoin: string;
}

export default function ProjectUsers(
  props: RouteComponentProps<IProjectUsersProps>
) {
  const snackbar = useSnackbar();
  const organizationId = props.match.params.organizationId;
  const projectId = props.match.params.projectId;

  const [organizationName, setOrganizationName] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const defaultProjectUsersState: IProjectUserInfo[] = [];
  const [projectUsers, setProjectUsers] = useState(defaultProjectUsersState);
  const [isLoading, setIsLoading] = useState(true);
  const defaultUsersState: IUserInfo[] = [];
  const [users, setUsers] = useState(defaultUsersState);

  const [openAssignUserDialog, setOpenAssignUserDialog] = useState(false);
  const [openRemoveUserDialog, setOpenRemoveUserDialog] = useState(false);
  const [userToRemove, setUserToRemove] = useState<IProjectUserInfo>();

  const [userEmail, setUserEmail] = useState('');
  const [textFieldError, setTextFieldError] = useState('');
  const [assignUserBusy, setAssignUserBusy] = useState(false);
  const [removeUserBusy, setRemoveUserBusy] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const fetchOrganization = async () => {
      const url = organizationUrl(organizationId);
      const organizationDto = (await get(url)) as IOrganizationDto;
      setOrganizationName(organizationDto.name);
    };
    fetchOrganization();
  }, [organizationId]);

  useEffect(() => {
    const fetchProject = async () => {
      const url = projectUrl(organizationId, projectId);
      const projectDto = (await get(url)) as IProjectDto;
      setProjectName(projectDto.name);
    };
    fetchProject();
  }, [organizationId, projectId]);

  useEffect(() => {
    const fetchUsersAssigned = async () => {
      const url = usersAssignedUrl(projectId);
      const userAssignedDtos = (await get(url)) as IAssignmentDto[];
      const data: IProjectUserInfo[] = [];
      for (const userAssignedDto of userAssignedDtos) {
        const userId = userAssignedDto.SK.split('#')[3];
        data.push({
          id: userId,
          name: userAssignedDto.name,
          email: userAssignedDto.email,
          dateOfJoin: userAssignedDto.dateOfJoin
        });
      }
      setProjectUsers(data);
      setIsLoading(false);
    };
    fetchUsersAssigned();
  }, [projectId]);

  useEffect(() => {
    const data: IUserInfo[] = [];
    const fetchData = async () => {
      let url = allOrganizationsUrl();
      const organizationDtos = (await get(url)) as IOrganizationDto[];
      for (const organizationDto of organizationDtos) {
        url = usersUrl(organizationDto.id);
        const userDtos = (await get(url)) as IUserDto[];
        for (const userDto of userDtos) {
          data.push(
            new UserInfo(userDto, organizationDto.id, organizationDto.name)
          );
        }
        setUsers(data);
      }
    };
    fetchData();
  }, [organizationName]);

  const handleOpenAssignUserDialog = () => {
    setOpenAssignUserDialog(true);
  };

  const handleCloseAssignUserDialog = () => {
    setOpenAssignUserDialog(false);
    setAssignUserBusy(false);
    setRemoveUserBusy(false);
    setTextFieldError('');
  };

  const userEmailExists = (value: string): boolean => {
    const names = projectUsers.map(({ email }) => email.toLowerCase());
    return names.includes(value.trim().toLowerCase());
  };

  const handleUserEmailChange = (e: any) => {
    handleUserEmailExist(e.target.value);
  };

  const handleUserEmailExist = (email: string) => {
    if (userEmailExists(email)) {
      setTextFieldError('User with the same email already exist');
    } else {
      setTextFieldError('');
    }
    setUserEmail(email);
  };

  const handleAssignUser = async () => {
    try {
      // Use the simplest way to test email, since email
      // needs validation.
      const emailParts = userEmail.split('@');
      const isValidEmail = emailParts.length === 2;
      if (!isValidEmail) {
        setTextFieldError('Not a valid email');
        return;
      }

      setAssignUserBusy(true);

      const auth0UsersWithEmail = await get(
        auth0UsersWithEmailUrl(userEmail.toLowerCase())
      );

      const auth0UserExist = auth0UsersWithEmail.length > 0;
      let auth0PostUserResponse;

      const emailDomain = emailParts[1];
      const isAdUser = emailDomain.toLowerCase() === CONFIG.auth.adDomain;

      // Only post new user to Auth0 if the user doesn't exist and
      // is not a Fugro user.
      if (!auth0UserExist && !isAdUser) {
        // This will trigger sending the verification email.
        auth0PostUserResponse = await post(auth0UsersUrl(), {
          email: userEmail
        });
      }

      // If user exists on auth0 get id from the response
      // assuming only one user with the same email, otherwise
      // get it from the post user response.
      let auth0UserId;
      if (auth0PostUserResponse) {
        // Posted a new user
        auth0UserId = auth0PostUserResponse.user_id;
      } else if (auth0UserExist) {
        auth0UserId = auth0UsersWithEmail[0].user_id;
      }

      let dto;
      const usersWithEmail = users.filter(
        u => u.email.toLowerCase() === userEmail.toLowerCase()
      );

      if (usersWithEmail.length > 0) {
        dto = usersWithEmail[0];
      }

      if (!dto) {
        dto = (await post(usersUrl(organizationId), {
          email: userEmail,
          // auth0UserId is not set for Fugro user, when we delete the user
          // we will use user's email instead.
          auth0UserId
        })) as IUserDto;

        setUsers([
          ...users,
          new UserInfo(dto, organizationId, organizationName)
        ]);
      }

      if (dto) {
        const url = assignUserUrl();
        const postUserResponse = await put(url, {
          projectId,
          userId: dto.id,
          organizationId
        });

        if (postUserResponse && postUserResponse.status === 200) {
          const authZAdmins = (await get(authZAdminsUrl())) as IAuthZClaim[];
          const sendEmailResponse = await put(welcomeEmailUrl(), {
            projectName,
            email: userEmail,
            ccAddresses: authZAdmins.map(x => x.email)
          });

          if (!sendEmailResponse || sendEmailResponse.status === 500) {
            snackbar.enqueueSnackbar(
              `Failed to send notification email to ${userEmail}`,
              {
                variant: 'warning'
              }
            );
          }

          setAssignUserBusy(false);
          snackbar.enqueueSnackbar(
            `User ${userEmail} successfully created and assigned to project ${projectName}`,
            {
              variant: 'success'
            }
          );
          const data = postUserResponse.data as IProjectUserInfo;
          setProjectUsers([...projectUsers, data]);
          handleCloseAssignUserDialog();
        }
      }
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  const handleCloseRemoveUserDialog = () => {
    setOpenRemoveUserDialog(false);
  };

  const handleOpenRemoveUserDialog = () => {
    setOpenRemoveUserDialog(true);
  };

  const handleRemoveUser = async () => {
    try {
      if (userToRemove) {
        setRemoveUserBusy(true);
        const userId = userToRemove.id;
        const url = unassignUserUrl();
        const response = await deleteResource(url, {
          userId,
          projectId,
          organizationId
        });
        if (response && response.status === 200) {
          setRemoveUserBusy(false);
          setProjectUsers(projectUsers.filter(x => x.id !== userId));
          snackbar.enqueueSnackbar(
            `User ${userEmail} successfully removed from project ${projectName}`,
            {
              variant: 'success'
            }
          );
        }
      }
      handleCloseRemoveUserDialog();
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  const removeUserOnClick = (
    row: IProjectUserInfo | IProjectUserInfo[]
  ): void => {
    if (row) {
      const projectUserInfo = row as IProjectUserInfo;
      if (projectUserInfo) {
        setUserToRemove(projectUserInfo);
        handleOpenRemoveUserDialog();
        setTextFieldError('');
      }
    }
  };

  const getRenderInput = (params: object): React.ReactNode => {
    return (
      <TextField
        {...params}
        label='Email'
        margin='dense'
        id='email'
        error={textFieldError.length !== 0}
        helperText={textFieldError}
        onChange={handleUserEmailChange}
        type='email'
        fullWidth={true}
      />
    );
  };

  const onAutoCompleteChange = (_event: object, value: string | null) => {
    if (value) {
      handleUserEmailExist(value);
    }
  };

  return (
    <div>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button className={classes.button} variant='contained' disabled={true}>
          Import Users
        </Button>
        <Button
          className={classes.button}
          color='primary'
          variant='contained'
          onClick={handleOpenAssignUserDialog}
        >
          Add User
        </Button>
      </div>
      <div className={classes.table}>
        <MaterialTable
          isLoading={isLoading}
          title={`Users assigned to ${projectName}`}
          columns={[
            {
              title: 'Email',
              field: 'email'
            },
            {
              title: 'Date of join',
              field: 'dateOfJoin'
            }
          ]}
          data={projectUsers}
          actions={[
            {
              icon: 'delete',
              tooltip: 'Remove user from project',
              onClick: (_event, row) => removeUserOnClick(row)
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: 5
          }}
        />
      </div>
      <GenericDialog
        open={openAssignUserDialog}
        title={'Add user'}
        message={'To add an user, please enter the details here.'}
        onClose={handleCloseAssignUserDialog}
        okButtonText={'Ok'}
        okButtonDisabled={userEmail.length === 0 || textFieldError !== ''}
        okHandler={handleAssignUser}
        cancelHandler={handleCloseAssignUserDialog}
        loading={assignUserBusy}
      >
        <Autocomplete
          id='email'
          options={users.map(x => x.email)}
          renderInput={getRenderInput}
          freeSolo={true}
          onChange={onAutoCompleteChange}
        />
      </GenericDialog>
      <ConfirmationDialog
        open={openRemoveUserDialog}
        title={`Are you sure you want to remove ${
          userToRemove === undefined ? 'user' : userToRemove.email
        } from project ${projectName}?`}
        onClose={handleCloseRemoveUserDialog}
        message={`Remove an user will revoke all the access to the project, the user can still access to other assigned project(s).`}
        yesHandler={handleRemoveUser}
        yesButtonDisabled={userToRemove === undefined}
        noHandler={handleCloseRemoveUserDialog}
        loading={removeUserBusy}
      />
    </div>
  );
}
