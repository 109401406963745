import React, { useEffect, useState } from 'react';
import { IStreamNameDto } from '../../types/dto.type';
import { get } from '../api/axios';
import { streamNamesUrl } from '../api/urls';
import { getStreamName } from '../routes/Routes';
import { VideoLayout } from './ProjectStreams';

interface BaseLayoutProps {
  streamIds: number[];
  children: React.ReactElement<any>;
}

export interface IdList {[id: number]: string; }

// NOTE: The optional params below are actually injected by parent BaseVideoLayout,
// but TS complains when using the component without specifying the parameter. Hence made optional.
export interface LayoutProps {
  projectId: string;
  streamIds: number[];
  setVideoLayout: React.Dispatch<React.SetStateAction<string>>;
  videoLayout: string;
  streamNames?: { [id: number]: string };
  propogateStreamNameChange?: (sNames: { [id: number]: string }) => void;
}

export const sessionStreamAssignment = (
  sessionVideoLayout: string,
  projectId: string,
  streamIds: number[],
  defaultStreamAssignment: {[pos: string]: number},
  setVideoLayout: React.Dispatch<React.SetStateAction<string>>,
  videoLayout: VideoLayout,
  videoLayoutCapacity: number
  ) => {
    let streamAssignment = defaultStreamAssignment;

    if (sessionVideoLayout) {
      const sessionLayout = JSON.parse(sessionVideoLayout);

      if (
        hasValidStoredVideoLayout(
          sessionVideoLayout,
          projectId,
          streamIds,
          videoLayout,
          videoLayoutCapacity
        )
      ) {
        streamAssignment =
          sessionLayout[projectId][VideoLayout[videoLayout]];
      } else {
        // save default streamAssignment to session state
        if (!sessionLayout[projectId]) {
          sessionLayout[projectId] = {
            [VideoLayout[videoLayout]]: defaultStreamAssignment
          };
        } else {
          sessionLayout[projectId][
            VideoLayout[videoLayout]
          ] = defaultStreamAssignment;
        }
        setVideoLayout(JSON.stringify(sessionLayout));
      }
    }

  return streamAssignment;
};

export const hasValidStoredVideoLayout = (
  sessionVideoLayout: string,
  projectId: string,
  streamIds: number[],
  videoLayout: VideoLayout,
  videoLayoutCapacity: number ) => {
    if (sessionVideoLayout) {
      const sessionLayout = JSON.parse(sessionVideoLayout);

      if (sessionLayout?.[projectId]?.[VideoLayout[videoLayout]]) {
        let occupiedPositionCount = 0;
        for (const [, value] of Object.entries<number>(
          sessionLayout?.[projectId]?.[VideoLayout[videoLayout]]
        ))  {
          if (!streamIds.includes(value) && value !== 0) {
            return false;
          }

          if (value) {
            ++occupiedPositionCount;
          }
        }

        if (streamIds.length <= videoLayoutCapacity) {
          if (occupiedPositionCount !== streamIds.length) {
            return false;
          }
        } else {
          if (occupiedPositionCount !== videoLayoutCapacity) {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }

  return true;
};

export default function BaseVideoLayout(props: BaseLayoutProps): JSX.Element {
  const defaultStreamNames: { [id: number]: string } = {};
  const [streamNames, setStreamNames] = useState(defaultStreamNames);

  useEffect(
    () => {
      const streamNamesState: { [id: number]: string } = {};
      for (const sid of props.streamIds) {
        streamNamesState[sid] = getStreamName(sid);
      }

      const fetchStreamNames = async () => {
        const streamNameDtos = (await get(
          streamNamesUrl()
        )) as IStreamNameDto[];

        for (const dto of streamNameDtos) {
          const id = parseInt(dto.id, 10);
          streamNamesState[id] = dto.name;
        }

        setStreamNames(streamNamesState);
      };

      fetchStreamNames();
    },
    [props.streamIds]
  );

  const propogateStreamNameChange = (sNames: { [id: number]: string }) => {
    setStreamNames(sNames);
  };

  return (
    <>
      {React.cloneElement(props.children, { streamNames, propogateStreamNameChange })}
    </>
  );
}
