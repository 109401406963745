import { Theme, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { AUTH, CONFIG } from '../services';
import { AUTH0_ERROR } from '../services/auth';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    playerWrapper: {
      position: 'relative',
      backgroundColor: 'black',
      textAlign: 'center'
    },
    overlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '70%',
      fontSize: '3em',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      color: 'white',
      background: 'radial-gradient(ellipse, #000 5%, transparent 70%)',
      padding: '0.5em 1em'
    },
    overlaySmall: {
      fontSize: '2em !important'
    },
    splashScreen: {
      width: '100%',
      height: '95.5vh',
      marginBottom: '-20px',
      objectFit: 'cover'
    }
  })
);

export default function Landing(): JSX.Element {
  const snackbar = useSnackbar();
  useEffect(() => {
    const auth0Error = localStorage.getItem(AUTH0_ERROR);

    if (!AUTH.isAuthenticated() && auth0Error !== null) {
      snackbar.enqueueSnackbar(`Authentication failed: ${auth0Error}`, {
        variant: 'error'
      });
    }
  });

  const isTabletOrMobile = useMediaQuery('(max-width: 800px)');
  const classes = useStyles();

  return (
    <div className={classes.playerWrapper}>
      <div />
      <video
        loop={true}
        muted={true}
        autoPlay={true}
        className={classes.splashScreen}
      >
        <source
          src={`${CONFIG.cloudfrontStaticContentUrl}/altitude.mp4`}
          type='video/mp4'
        />
      </video>
      <p />
      <div
        className={clsx(
          classes.overlay,
          isTabletOrMobile ? classes.overlaySmall : ''
        )}
      >
        Unlocking insights from <b>Geo-data</b>
      </div>
    </div>
  );
}
