import { AUTH, CONFIG } from '.';
import { fetchExtendClaims } from '../api/authZ.api';
import { get } from '../api/axios';
import { fullClaimUri } from '../common/authZ';
import { Claim, IAuthZ } from '../common/authZ.type';
import { extendClaimsUrl } from '../common/authZUrls';

const ORIGIN = window.location.origin;
const CLAIMS = `${ORIGIN}/claims`;

export class AuthZ implements IAuthZ {
  private _m2mBearerToken = '';
  _claims: Claim[] = [];

  public async setAuthZClaims(): Promise<void> {
    const userEmail = (await AUTH.fetchUserProfile()).email as string;
    await this.fetchAndStoreExtendClaims(userEmail);
  }

  async fetchAndStoreExtendClaims(user: string) {
    const req = {
      prefixes: CONFIG.authZApi.prefixes,
      claims: {
        email: user
      }
    };

    const claims = await fetchExtendClaims(req);
    localStorage.setItem(CLAIMS, JSON.stringify(claims[user]));
  }

  // TODO not used?
  async extendClaims(): Promise<Claim[]> {
    if (this._claims.length === 0) {
      const url = extendClaimsUrl();
      const data = await get(url);
      if (data) {
        this._claims = data.map((x: Claim) => x);
      }
    }

    return this._claims;
  }

  public getClaims(): string {
    return localStorage.getItem(CLAIMS) as string;
  }

  public clearClaims(): void {
    localStorage.removeItem(CLAIMS);
  }

  public M2MBearerToken(): string {
    return this._m2mBearerToken;
  }

  permitApp(): boolean {
    throw new Error('Method not implemented.');
  }

  async permitAppRole(role: string): Promise<boolean> {
    const claims = this.getClaims();
    if (!claims) {
      return false;
    }

    const userClaims: string[] = JSON.parse(claims);
    const roleFullUri = fullClaimUri(role);

    if (
      userClaims.find(val => {
        return roleFullUri === val;
      })
    ) {
      return true;
    }

    return false;
  }

  permitProject(_project: string): boolean {
    throw new Error('Method not implemented.');
  }

  permitAppProject(_project: string): boolean {
    throw new Error('Method not implemented.');
  }
}
